// react/gatsby imports
import { useStaticQuery, graphql } from "gatsby";

const usePrivacyQuery = () => {
  const prismicData = useStaticQuery(graphql`
    query privacyQuery {
      prismicLegaldocument(uid: { eq: "privacy" }) {
        data {
          legaldocadditionalinfo {
            richText
          }
          legaldocumentdate {
            text
          }
          legaldocumentdescription {
            richText
          }
          legaldocumenttype
        }
      }
      page: prismicPage(uid: { eq: "privacy" }) {
        uid
        data {
          seodescription {
            text
          }
          seoimage {
            url(imgixParams: { width: 1200 })
            thumbnails {
              vk {
                url(imgixParams: { width: 510 })
              }
            }
          }
        }
      }
    }
  `);

  return prismicData;
};

export default usePrivacyQuery;
